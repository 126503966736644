<template>
    <div class="content">
        <BreadCrumbs :elements="[{ link: 'Blog', name: 'Blog' }]" />
        <section class="post" v-if="Object.keys(model).length > 0">
            <div class="container">
                <h1>{{ model.title }}</h1>
                <article v-html="model.content"></article>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BreadCrumbs from "@/components/BreadCrumbs";

export default {
    name: "BlogPost",
    components: { BreadCrumbs },
    data() {
        return {
            model: {}
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_DIOS_URL;
        },
        appUrl: function() {
            return process.env.VUE_APP_APP_DIOS_URL;
        }
    },
    beforeMount() {
        this.getSearchData();
    },
    methods: {
        getSearchData() {
            ApiService.get(
                `${this.url}/blog/get/${this.$route.params.slug}`
            ).then(response => {
                this.model = response.data.data;
            });
        }
    }
};
</script>

<style scoped></style>
